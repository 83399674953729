import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import "antd/lib/button/";
import NavBar from "./NavBar.js";
import IssueCards from "./IssueCards.js";
import { isEmpty } from "lodash";
import { apiBase } from "./env.js";
import RequestFeature from "./RequestFeature";
import { CardControls } from "./CardControls";
import { Button } from "./styled/Button.js";
import { DEFERRED_STATE_ID } from "./styled/themes";
import { getFormToken } from "./auth";

const LoadMoreContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
  justify-content: center;
  color: inherit;
  padding-bottom: 16px;
`;

const LoadMoreButton = styled(Button)`
  padding: 8px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  background: #3a79ff;
  color: #fff;
  width: 200px;

  &:hover {
    background: #3a79ff;
  }
`;

export const IssueBoard = ({ user = {}, organizationName }) => {
  const [issues, setAllIssues] = useState([]),
    [devPhaseIssues, setDevPhaseIssues] = useState([]),
    [displayIssues, setDisplayIssues] = useState(issues),
    [searchText, setSearchText] = useState(""),
    [sortDate, toggleSortDate] = useState(false),
    [nextIssuesUrl, setNextIssuesUrl] = useState(),
    [requestFeatureModalVisible, setRequestFeatureModalVisible] = useState(
      false
    ),
    [selectedDevPhase, setSelectedDevPhase] = useState("All"),
    [filter, setFilter] = useState({});

  const getIssues = () => {
    axios
      .get(`${apiBase}/v2/proxy/listissues`, { withCredentials: true })
      .then((response) => {
        const { data, next } = response.data;
        setAllIssues(data);
        setDevPhaseIssues(data);
        setDisplayIssues(data);
        setNextIssuesUrl(next);
      });
  };

  useEffect(() => {
    getIssues();
  }, []);

  const getNextIssues = () => {
    axios(`${apiBase}/v2/proxy/listnextissues`, {
      method: "post",
      data: { Next: nextIssuesUrl },
      withCredentials: true,
      headers: {
        __FormToken: getFormToken()
      }
    }).then((response) => {
      const { data, next } = response.data,
        updatedIssues = issues.concat(data);
      setAllIssues(updatedIssues);
      updateDevIssues(selectedDevPhase, updatedIssues);
      setNextIssuesUrl(next);
    });
  };

  const onSearchChange = (e) => {
    e.preventDefault();
    const updatedSearchText = e.target.value;
    setSearchText(updatedSearchText);
  };

  const handleDevPhaseChange = (phase) => {
    setSelectedDevPhase(phase);
    updateDevIssues(phase);
  };

  const updateDevIssues = (phase = selectedDevPhase, allIssues = issues) => {
    const devIssues = allIssues.filter((issue) => {
      if (phase === "Completed") {
        return issue.completed;
      } else if (phase === "Started") {
        return issue.started && !issue.completed;
      } else if (phase === "Not Scheduled") {
        return !issue.started && !issue.completed;
      } else {
        return issue;
      }
    });
    setDevPhaseIssues(devIssues);
    handleNotScheduledFilter(filter);
  };

  const checkSearch = useCallback(() => {
    setFilter({});
    isEmpty(searchText)
      ? setDisplayIssues(devPhaseIssues)
      : setDisplayIssues(
        devPhaseIssues.filter(({ name, id, labels, story_type }) => {
          return [
            name,
            id.toString(),
            ...labels.map(({ name }) => name),
            story_type,
          ].some((string) =>
            string.toLowerCase().includes(searchText.toLowerCase())
          );
        })
      );
  }, [searchText, devPhaseIssues]);

  useEffect(() => {
    checkSearch();
  }, [checkSearch]);

  const handleNotScheduledFilter = (filter) => {
    if (!filter?.value) {
      setFilter({});
      if (selectedDevPhase === "Not Scheduled") {
        setDisplayIssues(devPhaseIssues);
      }
      return;
    } else if (filter.value === "deferred") {
      setDisplayIssues(
        devPhaseIssues.filter(
          (issue) => issue.workflow_state_id === DEFERRED_STATE_ID
        )
      );
    } else {
      setDisplayIssues(
        devPhaseIssues.filter(
          (issue) => issue.workflow_state_id !== DEFERRED_STATE_ID
        )
      );
    }
    setFilter(filter);
  };

  const sortTicketNum = () => {
    !sortDate ? toggleSortDate(true) : toggleSortDate(false);
    if (sortDate) {
      setDisplayIssues(
        displayIssues.sort(function (a, b) {
          return a.id - b.id;
        })
      );
    } else {
      setDisplayIssues(
        displayIssues.sort(function (a, b) {
          return b.id - a.id;
        })
      );
    }
  };

  const handleRequestFeatureClick = () => {
    setRequestFeatureModalVisible(!requestFeatureModalVisible);
  };

  return (
    <div style={{ minWidth: "750px" }}>
      <NavBar
        organizationName={organizationName}
        handleDevPhaseChange={handleDevPhaseChange}
        selectedDevPhase={selectedDevPhase}
        handleRequestFeatureClick={handleRequestFeatureClick}
      />
      <RequestFeature
        requestFeatureModalVisible={requestFeatureModalVisible}
        handleRequestFeatureClick={handleRequestFeatureClick}
        organizationName={organizationName}
        getIssues={getIssues}
      />
      <div
        className="App-body flex justify-center pt4"
        style={{ flexDirection: "column", padding: 0 }}
      >
        <CardControls
          selectedDevPhase={selectedDevPhase}
          onSearchChange={onSearchChange}
          activeSearch={!isEmpty(searchText)}
          sortTicketNum={sortTicketNum}
          handleNotScheduledFilter={handleNotScheduledFilter}
          filter={filter}
        />
        <IssueCards
          displayIssues={displayIssues}
          selectedDevPhase={selectedDevPhase}
          user={user}
        />
        {!!nextIssuesUrl && (
          <LoadMoreContainer>
            <LoadMoreButton onClick={getNextIssues}>Load more</LoadMoreButton>
          </LoadMoreContainer>
        )}
      </div>
    </div>
  );
};
