import React from "react";
import styled from "styled-components";
import { IssueCard } from "./IssueCard";

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Cards = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
  grid-gap: 16px;
  list-style: none;
  padding: 16px;
  width: 100%;
  justify-content: center;
  max-width: 1490px;
`;

const IssueCards = ({ displayIssues, user, selectedDevPhase }) => {
  return (
    <CardsContainer>
      <Cards>
        {displayIssues.length > 0 ? (
          displayIssues.map((issue) => (
            <IssueCard key={issue.id} issue={issue} user={user} />
          ))
        ) : (
          <div style={{ fontSize: "16px", textAlign: "center", marginTop: 10 }}>
            No tickets in <b>{selectedDevPhase}.</b>
          </div>
        )}
      </Cards>
    </CardsContainer>
  );
};

export default IssueCards;
