import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Button } from "./styled/Button";
import { devPhaseTheme, devPhases } from "./styled/themes";

const HeaderWrapper = styled.div`
  background: #fff;
  border-bottom-color: rgb(232, 232, 232);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  line-height: 46px;
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  min-width: 550px;

  h1 {
    font-size: 1.5em;
    color: rgba(0, 0, 0, 0.65);
    position: absolute;
    margin-left: 16px;
    visibility: visible;
  }

  ul {
    margin-bottom: 0px;
    margin-left: 0px;
    width: 100%;
    margin: auto;
    text-align: center;
  }

  @media (max-width: 650px) {
    h1 {
      visibility: hidden;
    }
  }
`;

const NavItem = styled.li`
  font-size: 1em;
  display: inline-block;
  padding: 12px 20px;
  min-width: 120px;
  ${({ active }) =>
    active &&
    ` 
    color: #3a79ff;
    border-bottom-color: #3a79ff;
    border-bottom-style: solid;
  `};

  &:hover {
    color: ${({ theme, phase }) => `${theme[phase]}`};
    border-color: ${({ theme, phase }) => `${theme[phase]}`};
    border-bottom-style: solid;
    cursor: pointer;
  }
`;

const RequestFeatureButton = styled(Button)`
  font-size: 0.9em;
  margin: auto;
  margin-right: 10px;
  padding: 10px;
  white-space: nowrap;
`;

const NavBar = ({
  organizationName,
  handleDevPhaseChange,
  selectedDevPhase,
  handleRequestFeatureClick,
}) => (
  <div>
    <HeaderWrapper>
      {/* TO DO: get org picture
       */}
      <h1>{organizationName}</h1>
      <ul>
        <ThemeProvider theme={devPhaseTheme}>
          {devPhases.map((phase) => (
            <NavItem
              key={phase}
              phase={phase.toLowerCase()}
              active={selectedDevPhase === phase ? true : false}
              onClick={() => handleDevPhaseChange(phase)}
            >
              {phase}
            </NavItem>
          ))}
        </ThemeProvider>
      </ul>
      <RequestFeatureButton onClick={handleRequestFeatureClick}>
        Request Feature
      </RequestFeatureButton>
    </HeaderWrapper>
  </div>
);

export default NavBar;
