import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Icon, Tooltip } from "antd";
import { storyTypeTheme } from "./styled/themes.js";
import { startCase } from "lodash";

const Header = styled.div`
  display: flex;
  font-size: ${({ fontSize }) => (fontSize !== "" ? `${fontSize};` : "10px;")}
  
  h4 {
    flex: 1;
    font-weight: 400;
  ${({ modal }) =>
    !modal &&
    `  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  `}
  }

  h4, h6 {
    margin: 0;
  }

  h6 {
    font-weight: normal;
  }
}`;

export const CardHeader = ({
  issue: {
    name,
    id,
    created_at: createdAt,
    story_type: storyType,
    description,
  },
  fontSize,
  marginTop,
  modal,
}) => {
  const reportedBy =
    description.indexOf("Reported by") >= 0
      ? description.slice(
          description.indexOf(":") + 1,
          description.indexOf("(")
        )
      : "";

  return (
    <Header modal={modal} fontSize={fontSize} marginTop={marginTop}>
      <h4>{name}</h4>
      <h6 style={{ marginRight: "10px", marginTop: "3px" }}>
        <Tooltip
          title={
            <div style={{ textAlign: "center" }}>
              {`Created ${
                reportedBy.length > 0 ? `by ${startCase(reportedBy)}` : ""
              } on
              ${moment(createdAt).format("MM/DD h:mm a")}`}
            </div>
          }
          overlayStyle={{ minWidth: "40px" }}
        >
          {`#${id}`}
        </Tooltip>
        <Tooltip
          title={
            <div style={{ textAlign: "center" }}>{startCase(storyType)}</div>
          }
          overlayStyle={{ minWidth: "40px" }}
        >
          <Icon
            type={storyTypeTheme[storyType].type}
            style={{
              fontSize: modal && "1em",
              marginRight: modal && "18px",
              color: `${storyTypeTheme[storyType].color}`,
              marginLeft: "4px",
            }}
            theme="filled"
          />
        </Tooltip>
      </h6>
    </Header>
  );
};
