import React, { useState, useEffect, useCallback } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Icon, Tooltip } from "antd";
import CardModal from "./CardModal";
import axios from "axios";
import { apiBase } from "./env.js";
import { CardHeader } from "./CardHeader";
import {
  priorityOptions,
  priorityLevelTheme,
  securityConcernTheme,
  devPhaseTheme,
  DEFERRED_STATE_ID,
} from "./styled/themes";
import { getFormToken } from "./auth";


const Card = styled.li`
  text-align: left;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 16px 16px 6px;
  background: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  min-height: 92px;
  border-left-style: solid;

  ${({ devPhase, theme }) => `border-left-color: ${theme[devPhase]};`}

  &:hover {
    box-shadow: 0 0 0 3px #d2dffb, 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  }

  ${({ isDeferred }) =>
    isDeferred &&
    `background: #d3d3d375; 
    border-top: 1px solid #80808047; 
    border-right: 1px solid #80808047; 
    border-bottom: 1px solid #80808047;`}
`;

const Label = styled.span`
  padding: 1px 8px;
  border-radius: 25px;
  margin: 4px;
  margin-left: 0px;
  color: #000000a6;
  border: 1px solid #00000017;
  font-size: 9px;
  display: inline-block;
`;

export const IssueCard = ({
  issue,
  issue: { labels, started, completed, workflow_state_id: workflowStateId },
  user,
}) => {
  const cleanLabels = (labels) => {
    const labelOptions = [...priorityOptions, "Hotfix", "Security Concern"];
    return [
      ...labels.filter((label) => labelOptions.indexOf(label.name) >= 0),
    ];
  },
    [cardLabels, setCardLabels] = useState(cleanLabels(labels)),
    [modalVisible, setModalVisible] = useState(false),
    [filteredComments, setAllComments] = useState([]);

  const fetchComments = useCallback(() => {
    return axios(`${apiBase}/v2/proxy/getissue`, {
      method: "post",
      data: { StoryId: issue.id },
      withCredentials: true,
      headers: {
        __FormToken: getFormToken()
      }
    }).then((response) => {
      if (response?.data?.comments?.length > 0) {
        const { comments } = response.data;
        if (comments[0].text.indexOf("app.logrocket") >= 0) {
          setAllComments(comments.slice(1));
        } else {
          setAllComments(comments);
        }
      }
    });
  }, [issue.id]);

  useEffect(() => {
    fetchComments(issue.id);
  }, [issue.id, fetchComments]);

  const handleLabelsChange = (newLabels) => {
    setCardLabels(cleanLabels(newLabels));
  };

  const handleModalClose = () => {
    setModalVisible(false);
  };

  const labelTheme = { ...priorityLevelTheme, ...securityConcernTheme };

  const styledLabels =
    cardLabels.length > 0 &&
    cardLabels.map((label, i) => {
      const { name: labelName, id: labelId } = label,
        labelNameNoSpaces =
          labelName === "Security Concern" ? "SecurityConcern" : labelName,
        { color: iconColor, type: iconType } = labelTheme[
          labelNameNoSpaces.toLowerCase()
        ];
      return (
        <Tooltip
          title={labelNameNoSpaces !== "SecurityConcern" && "Priority Level"}
          key={`${labelName}-${labelId}-${i}`}
          placement={"bottom"}
        >
          <Label key={labelId} name={labelName}>
            <Icon
              type={iconType}
              style={{
                fontSize: "10px",
                marginRight: "6px",
                marginTop: "3px",
                color: `${iconColor}`,
              }}
              theme="outlined"
            />
            {labelName.toUpperCase()}
          </Label>
        </Tooltip>
      );
    });

  const getDevPhase = () => {
    if (completed) {
      return "completed";
    } else if (!completed && started) {
      return "started";
    } else {
      return "unscheduled";
    }
  };

  const isDeferred = workflowStateId === DEFERRED_STATE_ID;
  return (
    <ThemeProvider theme={devPhaseTheme}>
      <Card
        key={issue.id}
        id="card"
        onMouseDown={() => setModalVisible(true)}
        devPhase={getDevPhase()}
        isDeferred={isDeferred}
      >
        <Tooltip
          title={isDeferred && "Deferred: ticket not chosen for development"}
        >
          <CardHeader issue={issue} modal={false} />
          <div>
            {styledLabels}
            {filteredComments.length > 0 && (
              <Tooltip title={"Comments"} placement={"bottom"}>
                <Label>
                  <Icon
                    type="message"
                    style={{
                      fontSize: "10px",
                      marginRight: "6px",
                      marginTop: "3px",
                    }}
                    theme="outlined"
                  />
                  {filteredComments.length}
                </Label>
              </Tooltip>
            )}
          </div>
        </Tooltip>
        <CardModal
          visible={modalVisible}
          handleModalClose={handleModalClose}
          issue={issue}
          user={user}
          handleLabelsChange={handleLabelsChange}
          cardLabels={cardLabels}
        />
      </Card>
    </ThemeProvider>
  );
};
