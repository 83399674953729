import styled from "styled-components";

export const Input = styled.input`
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 8px 12px;
  margin-right: 32px;
  cursor: pointer;
  &:focus {
    box-shadow: 0 0 0 3px rgba(58, 121, 255, 0.1);
  }

  &:hover {
    box-shadow: 0 0 0 3px rgba(58, 121, 255, 0.1);
  }
`;

export const TextArea = styled.textarea`
  outline: none !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 8px 12px;
  margin-right: 32px;
  width: 100%;
  &:focus {
    box-shadow: 0 0 0 3px rgba(58, 121, 255, 0.1);
  }
`;
