import registerServiceWorker from "./registerServiceWorker";
import Component from "react-component-component";
import { getOrganization } from "./auth";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, withRouter } from "react-router-dom";
import App from "./App";
import "tachyons";
import "./index.css";

const RootComponent = ({ history }) => (
  <Component
    initialState={{ account: null, statusFilter: "OPEN", searchText: "" }}
    didMount={({ setState }) => {
      getOrganization().then((response) => {
        if (response?.status === 200) {
          const { data } = response;
          localStorage.setItem("__FormToken", data.security.formToken);
          setState({ account: data });
          history.replace("/board");
        } else {
          window.location =
            "https://app.twinthread.com/Account/Login?ReturnUrl=" +
            encodeURIComponent(window.location.origin);
        }
      });
    }}
  >
    {({ state, setState }) => (
      <Route
        exact
        path="/board"
        render={() =>
          state.account && (
            <App
              status={state.statusFilter}
              onStatusFilterChange={(statusFilter) => {
                setState({ statusFilter });
              }}
              searchText={state.searchText}
              onSearchChange={(searchText) => {
                setState({ searchText });
              }}
              account={state.account}
              organization={state.account.organization}
              onAccountChange={(account) => setState({ account })}
            />
          )
        }
      />
    )}
  </Component>
);

const Root = withRouter(RootComponent);

ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById("root")
);
registerServiceWorker();
