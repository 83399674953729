import React, { useState } from "react";
import { Modal, message, Spin } from "antd";
import styled, { ThemeProvider } from "styled-components";
import { apiBase } from "./env.js";
import axios from "axios";
import { isEmpty } from "lodash";
import { Button } from "./styled/Button";
import { Input, TextArea } from "./styled/Input";
import {
  DEFAULT_FEATURE_PRIORITY,
  priorityOptions,
  priorityLevelTheme,
} from "./styled/themes";
import { getFormToken } from "./auth";

const TitleInput = styled(Input)`
  width: 100%;
  margin-bottom: 10px;
`;

const DescriptionInput = styled(TextArea)`
  margin-bottom: 10px;
`;

const RadioButton = styled(Button)`
  flex: 1;
  border-radius: 0px;
  width: 33%;
  border-right-width: 0px;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }

  ${({ isPriority, theme, radioOption }) =>
    isPriority &&
    `color: white; 
      border-color: ${theme[radioOption].color};
      background-color: ${theme[radioOption].color};
      
      &:hover {
        background: ${theme[radioOption].color};
      }
      
      `}
`;

const RequestFeature = ({
  requestFeatureModalVisible,
  handleRequestFeatureClick,
  getIssues,
}) => {
  const [title, setTitle] = useState(""),
    [description, setDescription] = useState(""),
    [priorityLevel, setPriorityLevel] = useState(DEFAULT_FEATURE_PRIORITY),
    [isSubmitLoading, setSubmitLoading] = useState(false);

  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmitFeature = () => {
    if (!isEmpty(title) && !isEmpty(description)) {
      setSubmitLoading(true);
      axios(`${apiBase}/v2/proxy/reportissue`, {
        method: "post",
        data: {
          title,
          body: description,
          labels: [{ name: priorityLevel }],
          commments: undefined,
          storyType: "feature",
        },
        withCredentials: true,
        headers: {
          __FormToken: getFormToken()
        }
      })
        .then((response) => {
          handleRequestFeatureClick();
          setTitle("");
          setDescription("");
          setSubmitLoading(false);
          if (response.status === 200) {
            message.success("Your feature has been requested.");
            setTimeout(() => getIssues(), 2000);
          } else {
            message.error("Feature request failed.");
          }
        })
        .catch((error) => {
          message.error("Feature request failed.");
        });
    } else {
      message.error("Title and description are required fields.");
    }
  };

  const handlePriorityChange = (e) => {
    const level = e.target.dataset.index;
    setPriorityLevel(level);
  };

  return (
    <div>
      <Modal
        visible={requestFeatureModalVisible}
        title="Request a new feature"
        onCancel={handleRequestFeatureClick}
        afterClose={() => setPriorityLevel(DEFAULT_FEATURE_PRIORITY)}
        destroyOnClose={true}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              key="back"
              onClick={handleRequestFeatureClick}
              style={{ marginRight: "5px" }}
            >
              Cancel
            </Button>
            <Spin spinning={isSubmitLoading}>
              <Button
                key="submit"
                type="primary"
                onClick={handleSubmitFeature}
                style={{ backgroundColor: "#3a79ff", color: "white" }}
              >
                Submit Feature
              </Button>
            </Spin>
          </div>
        }
      >
        Title*
        <TitleInput
          placeholder="Enter a title for your feature"
          onChange={onTitleChange}
        />
        Description*
        <DescriptionInput
          rows="10"
          placeholder="Describe your feature"
          onChange={onDescriptionChange}
        />
        <div style={{ marginBottom: "20px" }}>
          <div>Feature Priority</div>
          <div>
            {priorityOptions.map((option, i) => (
              <ThemeProvider theme={priorityLevelTheme} key={`${option}-${i}`}>
                <RadioButton
                  data-index={option}
                  onClick={handlePriorityChange}
                  isPriority={priorityLevel === option}
                  radioOption={option.toLowerCase()}
                >
                  {option}
                </RadioButton>
              </ThemeProvider>
            ))}
          </div>
        </div>
        <p>
          Note: Requested features will be considered for development, but are
          not guaranteed to be implemented.
        </p>
      </Modal>
    </div>
  );
};

export default RequestFeature;
