import styled from "styled-components";

export const Button = styled.button`
  transition: all 0.2s linear;
  border-radius: 6px;
  font-size: 14px;
  padding: 8px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  background: #fff;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

export const RadioButton = styled(Button)`
  flex: 1;
  border-radius: 0px;
  width: 33%;
  border-right-width: 0px;
  padding: 5px;
  font-size: 10px;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-width: 1px;
  }

  ${({ isPriority, theme, radioOption }) =>
    isPriority &&
    `color: white; 
      border-color: ${theme[radioOption].color};
      background-color: ${theme[radioOption].color};
      
      &:hover {
        background: ${theme[radioOption].color};
      }
      
      `}
`;
