import "./App.css";
import React from "react";
import { IssueBoard } from "./IssueBoard";

const App = ({ account = {}, organization }) => {
  return (
    <IssueBoard user={account.user} organizationName={organization.name} />
  );
};

export default App;
