import axios from "axios";
import { apiBase } from "./env.js";

export const getOrganization = () =>
  axios.post(
    `${apiBase}/Organization/Open`,
    {},
    { withCredentials: true }
  );


export const login = (email, password) =>
  axios.post(`${apiBase}/Account/Login`, {
    email,
    password,
    rememberme: false
  });

export const getFormToken = () => {
  return localStorage.getItem("__FormToken") ?? undefined;
};