import React from "react";
import styled from "styled-components";
import { Button } from "./styled/Button";
import { Input } from "./styled/Input";
import Select from "react-select";
import { notScheduledFilterOptions } from "./styled/themes.js";

const ControlsWrapper = styled.div`
  width: 60%;
  margin: auto;
  text-align: center;
  padding: 16px 0px 0px;
  display: flex;
  justify-content: center;
`;

const Search = styled(Input)`
  margin-right: 10px;
  padding: 8px 12px;
  width: 400px;
  height: 38px;

  ${({ activeSearch }) =>
    activeSearch &&
    `box-shadow: 0 0 0 3px rgba(58, 121, 255, 0.1);
`};
`;

const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: 6,
    border: "1px solid rgba(0,0,0,0.2)",
    cursor: "pointer",
    "&:hover": { boxShadow: "0 0 0 3px rgba(58, 121, 255, 0.1)" },
    boxShadow: state.isFocused && "",
  }),
  container: (provided) => ({
    ...provided,
    width: "150px",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "rgba(0, 0, 0, 0.65)",
  }),
};

export const CardControls = ({
  selectedDevPhase,
  onSearchChange,
  activeSearch,
  sortTicketNum,
  handleNotScheduledFilter,
  filter,
}) => {
  return (
    <ControlsWrapper>
      <Search
        placeholder={`Search tickets by name, id, type, or priority level`}
        onChange={onSearchChange}
        activeSearch={activeSearch}
      />
      {selectedDevPhase === "Not Scheduled" && (
        <Select
          options={notScheduledFilterOptions}
          placeholder="Filter by..."
          isClearable={true}
          styles={selectStyles}
          onChange={handleNotScheduledFilter}
          value={filter?.value ? filter : ""}
        />
      )}
      {/* TODO: reinstate date sort with better approach */}
      {/* <Button onClick={sortTicketNum}>Sort by Date Created</Button> */}
    </ControlsWrapper>
  );
};
