export const DEFAULT_FEATURE_PRIORITY = "Medium";
export const DEFERRED_STATE_ID = 500000239;

export const priorityOptions = ["Low", "Medium", "High"];
export const devPhases = ["All", "Not Scheduled", "Started", "Completed"];
export const notScheduledFilterOptions = [
  { value: "deferred", label: "Deferred" },
  { value: "in-review", label: "In Review" },
];

export const storyTypeTheme = {
  bug: { color: "#ca5e5e", type: "bug" },
  feature: { color: "#f3d76e", type: "star" },
  chore: { color: "#000000a6", type: "tool" },
};

export const priorityLevelTheme = {
  low: { color: "#3a79ff", type: "exclamation-circle" },
  medium: { color: "#15bc4d", type: "exclamation-circle" },
  high: { color: "#f86550", type: "exclamation-circle" },
  hotfix: { color: "#c7341f", type: "exclamation-circle" },
};

export const securityConcernTheme = {
  securityconcern: { color: "#000000a6", type: "safety" },
};

export const devPhaseTheme = {
  unscheduled: "gray",
  started: "rgb(243, 215, 110)",
  completed: "rgb(21, 188, 77)",
};
