import React, { useState, useEffect } from "react";
import Modal from "antd/lib/modal"; // for js
import styled from "styled-components";
import { Icon, Switch, message, Dropdown, Menu } from "antd";
import Comment from "./Comment";
import axios from "axios";
import { apiBase } from "./env.js";
import { CardHeader as Header } from "./CardHeader";
import { priorityOptions, priorityLevelTheme } from "./styled/themes";
import { startCase } from "lodash";
import { getFormToken } from "./auth";

const Content = styled.div`
  padding: 16px 24px 0px;
  border-radius: 4px 4px 0 0;
  margin-right: 10px;
  width: 100%;

  h5 {
    font-weight: 300;
    font-size: 14px;
    margin-top: 10px;
  }
`;

const SectionHeader = styled.h4`
  border-bottom: 1px solid #e8e8e8;
  font-weight: 400;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 16px;
  padding-bottom: 5px;
`;

const SectionSubText = styled.h4`
  color: #999;
  font-weight: 400;
  font-size: 14px;
  margin-right: 5px;
`;

const SECURITY_CONCERN_LABEL_ID = 3001;

const CardModal = ({
  issue,
  issue: { description, id, labels: issueLabels },
  visible,
  handleModalClose,
  user,
  handleLabelsChange,
  cardLabels,
}) => {
  const [priorityLevel, setPriorityLevel] = useState("None"),
    [labels, setLabels] = useState(cardLabels),
    onLoadIsSecurityConcern =
      labels.filter(
        (label) =>
          label.id === SECURITY_CONCERN_LABEL_ID &&
          label.name === "Security Concern"
      ).length === 1,
    [isSecurityConcern, setSecurityConcern] = useState(onLoadIsSecurityConcern);

  const updateIssueLabels = (storyLabelsDict) => {
    axios(`${apiBase}/v2/proxy/updatestorylabels`, {
      method: "post",
      data: { StoryLabels: storyLabelsDict },
      withCredentials: true,
      headers: {
        __FormToken: getFormToken()
      }
    }).catch((error) => message.error("Operation failed"));
  };

  useEffect(() => {
    checkForPriorityLabel();
  }, []);

  const checkForPriorityLabel = () => {
    let priority = "";
    cardLabels.map((label) => {
      if (priorityOptions.indexOf(label.name) >= 0) {
        priority = label.name;
      }
    });

    priority === "" ? setPriorityLevel("None") : setPriorityLevel(priority);
  };

  const handlePriorityChange = ({ key: newPriority }) => {
    if (newPriority !== priorityLevel) {
      message.success("Priority Level Saved");
      const newLabels = [
        { name: startCase(newPriority) },
        ...issueLabels.filter(
          (label) => priorityOptions.indexOf(label.name) < 0
        ),
      ];
      setLabels(newLabels);
      setPriorityLevel(newPriority);
    }
  };

  const handleModalSave = () => {
    const storyLabelsDict = {};
    let saveLabels = [];
    if (isSecurityConcern) {
      const securityLabelFound = labels.some(
        (label) => label.name === "Security Concern"
      );
      securityLabelFound
        ? (saveLabels = [...labels])
        : (saveLabels = [
          ...labels,
          {
            name: "Security Concern",
            id: SECURITY_CONCERN_LABEL_ID,
          },
        ]);
    } else {
      saveLabels = labels.filter(
        (label) => label.id !== SECURITY_CONCERN_LABEL_ID
      );
    }
    const twinthreadOnlyLabels = issueLabels.filter(
      (label) =>
        [...priorityOptions, "Security Concern"].indexOf(label.name) < 0
    );
    storyLabelsDict[id] = [...saveLabels, ...twinthreadOnlyLabels];
    updateIssueLabels(storyLabelsDict);
    handleLabelsChange(storyLabelsDict[id]);
    handleModalClose();
  };

  const menu = (
    <Menu onClick={handlePriorityChange} style={{ width: "75px" }}>
      {priorityOptions.map((option) => (
        <Menu.Item
          key={option}
          option={option}
          style={{ padding: "8px", fontSize: "11px" }}
        >
          {option}
        </Menu.Item>
      ))}
    </Menu>
  );

  const handleSecurityChange = () => {
    message.success("Security Status Saved");
    setSecurityConcern(!isSecurityConcern);
  };

  const ATTACHED_IMAGE_FULL_TEXT_PATTERN = /!\[.*\]\(.*\)/g,
    ATTACHED_IMAGE_URL_PATTERN = /https:\/\/media.clubhouse.io\/api\/attachments\/files\/clubhouse-assets\/.*.png/g;

  return (
    <Modal
      visible={visible}
      onCancel={handleModalSave}
      okText={"Save"}
      bodyStyle={{ padding: "0px" }}
      width={700}
      destroyOnClose={true}
      footer={null}
    >
      <Content>
        <Header
          issue={issue}
          fontSize={"1.3em"}
          marginTop={"10px"}
          modal={true}
        />
        <h5 style={{ color: "rgba(0,0,0,0.65)" }}>
          {description.length > 0
            ? description.replace(ATTACHED_IMAGE_FULL_TEXT_PATTERN, "")
            : "No description"}
        </h5>
        {description?.match(ATTACHED_IMAGE_URL_PATTERN)?.map((image) => (
          <img src={image} />
        ))}
        <div style={{ display: "flex", width: "100%", marginTop: "8px" }}>
          <div style={{ display: "flex", minWidth: "80px" }}>
            <Dropdown overlay={menu}>
              <SectionSubText
                style={{
                  color:
                    priorityLevel === "None"
                      ? "#999"
                      : priorityLevelTheme[priorityLevel.toLowerCase()].color,
                  cursor: "pointer",
                }}
              >
                <Icon
                  type="exclamation-circle"
                  style={{
                    fontSize: "14px",
                    marginRight: "3px",
                  }}
                  theme="outlined"
                />
                {priorityLevel}
              </SectionSubText>
            </Dropdown>
          </div>
          <div style={{ display: "flex" }}>
            <SectionSubText>Security Concern</SectionSubText>
            <Switch
              style={{ color: "#EE0000", marginTop: "2px" }}
              size="small"
              onClick={handleSecurityChange}
              checked={isSecurityConcern}
            />
          </div>
        </div>
        <SectionHeader>Comments</SectionHeader>
        <Comment user={user} issue={issue} />
      </Content>
    </Modal>
  );
};

export default CardModal;
