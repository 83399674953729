import React, { useState, useEffect, useCallback } from "react";
import { isEmpty } from "lodash";
import styled from "styled-components";
import "antd/lib/modal/style/css";
import axios from "axios";
import moment from "moment";
import { Button } from "./styled/Button";
import { Input } from "./styled/Input";
import { message, Tooltip } from "antd";
import { apiBase } from "./env.js";
import { startCase } from "lodash";
import { MdSend } from "react-icons/md";
import { getFormToken } from "./auth";

const InputWrapper = styled.div`
  border-radius: 6px;
  display: flex;
  margin: 10px 0px 15px;
  width: 100%;
`;

const CommentInput = styled(Input)`
  width: 100%;
  margin-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: 0px;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    box-shadow: none;
  }
`;
const SendButton = styled(Button)`
  width: 40px;
  font-size: 15px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left: 0px;

  ${({ activeSearch }) =>
    activeSearch &&
    ` color: rgb(58, 121, 255);
`};
`;

const Comments = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column-reverse;
  height: 400px;
  overflow-y: auto;
  margin-bottom: 0;
  padding: 16px 10px 5px;
`;

const Placement = styled.li`
  align-self: ${({ fromCustomer }) =>
    !fromCustomer ? "flex-start" : "flex-end"};
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  &:first-child {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
`;

const Bubble = styled.div`
  border-radius: 20px;
  padding: 8px 16px 6px;
  background: rgba(0, 0, 0, 0.05);
  margin-left: -8px;
  margin-right: 32px;
  display: inline-block;
  font-size: 13px;

  ${({ fromCustomer }) =>
    fromCustomer
      ? `
    background: #3a79ff;
    color: #fff;
    margin-right: 0px;
  `
      : ""};
`;

const Timestamp = styled.h5`
  font-weight: normal;
  color: #999;

  ${({ fromCustomer }) =>
    fromCustomer
      ? `
    align-self: flex-end;
  `
      : ""};
`;

const Comment = ({ user, issue }) => {
  const [allStoryComments, setAllComments] = useState([]),
    [comment, setComment] = useState(""),
    { UserName: userName = "", FirstName = "", LastName = "" } = user,
    firstName = startCase(FirstName),
    lastName = startCase(LastName),
    commentsRef = useCallback((node) => {
      if (!!node) node.scrollTop = node.scrollHeight;
    }, []);

  const fetchComments = useCallback(() => {
    return axios(`${apiBase}/v2/proxy/getissue`, {
      method: "post",
      data: { StoryId: issue.id },
      withCredentials: true,
      headers: {
        __FormToken: getFormToken()
      }
    }).then((response) => {
      if (response?.data?.comments?.length > 0) {
        const { comments } = response.data;
        if (comments[0].text.indexOf("app.logrocket") >= 0) {
          setAllComments(comments.slice(1));
        } else {
          setAllComments(comments);
        }
      }
    });
  }, [issue.id]);

  useEffect(() => {
    fetchComments(issue.id);
  }, [issue.id, fetchComments]);

  const onCommentChange = (e) => {
    setComment(e.target.value);
  };

  const submitComment = () => {
    const data = {};
    const fullName =
      firstName.length + lastName.length > 0
        ? ` (${firstName} ${lastName})`
        : "";
    data.text = `${userName}${fullName}: ${comment}`;
    if (comment !== "") {
      axios(`${apiBase}/v2/proxy/postcomment`, {
        method: "post",
        data: { StoryId: issue.id, Text: data.text },
        withCredentials: true,
        headers: {
          __FormToken: getFormToken()
        }
      })
        .then((response) => fetchComments(issue.id))
        .catch((error) => message.error("Operation failed"));

      document.getElementById("commentInput").value = "";
      setComment("");
    }
  };

  const getCommentMadeBy = (text) => {
    const namePattern = /\((\S.*|\s\S.*)\):/,
      emailPattern = /@*.com/,
      hasName = namePattern.test(text),
      getName = () => {
        return text.slice(text.indexOf("(") + 1, text.indexOf(")"));
      },
      hasEmail = emailPattern.test(text),
      getEmail = () => {
        return text.slice(0, text.indexOf(":"));
      };

    return hasName ? getName() : hasEmail ? getEmail() : "";
  };

  const checkIsCurrentUser = (commentMadeBy) => {
    return (
      commentMadeBy === userName || commentMadeBy === `${firstName} ${lastName}`
    );
  };

  const commentsNewtoOld = allStoryComments.slice().reverse();
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Comments ref={commentsRef} style={{ position: "relative" }}>
        {allStoryComments.length > 0 ? (
          commentsNewtoOld.map((comment) => {
            const { text, created_at: createdAt } = comment,
              fromCustomer = !text.includes("@twinthread.com"),
              commentMadeBy = getCommentMadeBy(text),
              isCurrentUser = checkIsCurrentUser(commentMadeBy),
              commentWithoutMadeBy = text.substring(text.indexOf(":") + 1);

            return (
              <Placement fromCustomer={fromCustomer} key={comment.id}>
                <div style={{ alignSelf: fromCustomer ? "flex-end" : "" }}>
                  <Bubble fromCustomer={fromCustomer}>
                    {commentWithoutMadeBy}
                  </Bubble>
                </div>
                <Timestamp
                  fromCustomer={fromCustomer}
                  style={{ margin: "0px", fontSize: "13px" }}
                >
                  {isCurrentUser ? "You on " : `${commentMadeBy} on `}
                  {moment(createdAt).format("MM/DD h:mm a")}
                </Timestamp>
              </Placement>
            );
          })
        ) : (
          <h4
            style={{
              flex: 1,
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.65)",
            }}
          >
            There is no comment history.
          </h4>
        )}
      </Comments>
      <InputWrapper>
        <CommentInput
          type="text"
          onChange={onCommentChange}
          onKeyPress={(e) => e.key === "Enter" && submitComment()}
          id="commentInput"
          placeholder={"Share a new comment..."}
          autoComplete={"off"}
        />
        <Tooltip
          title={comment !== "" && "Send message"}
          style={{ fontSize: "15px" }}
        >
          <SendButton activeSearch={!isEmpty(comment)} onClick={submitComment}>
            <MdSend />
          </SendButton>
        </Tooltip>
      </InputWrapper>
    </div>
  );
};

export default Comment;
